<template>
  <div
    class="m-cart"
    :style="'--aog-totalprice:\'' + formatMoney(totalPrice / 100) + '\''"
  >
    <div class="my-cart">
      <template v-if="cart.length > 0">
        <div class="header-menu">
          <span>{{ PAGELANG.cart }}<i class="iconfont icon-right-02" /></span>
          <span
            class="btn"
            @click="
              showDelete = !showDelete;
              selectAll = false;
              checkAll();
            "
            >{{ showDelete ? PAGELANG.complete : PAGELANG.manage }}</span
          >
        </div>
        <div class="header-menu-placeholder"></div>

        <!--<van-pull-refresh
          v-model="refreshing"
          @refresh="onRefresh"
          :pulling-text="SYSLANG.下拉即可刷新"
          :loosing-text="SYSLANG.释放即可刷新"
          :loading-text="SYSLANG.loading"
        >-->
        <van-list
          v-model:loading="loading"
          :finished="finished"
          :finished-text="SYSLANG.finished"
          :loading-text="SYSLANG.loading"
          @load="getCart"
          :style="'--goods-lose-efficacy:\'' + PAGELANG.loseefficacy + '\''"
        >
          <div v-for="(item, index) in cart" :key="index" class="cart-item">
            <van-swipe-cell class="content">
              <template #default>
                <div
                  style="
                    display: flex;
                    width: 100%;
                    height: 100px;
                    padding: 15px 0;
                  "
                >
                  <van-checkbox
                    v-model="item.selected"
                    @change="onChange"
                    :disabled="item.Status == 0"
                  >
                    <van-image
                      width="100"
                      height="100"
                      radius="8"
                      :src="UPLOADBASE + item.PdtThumb"
                      fit="cover"
                    />
                  </van-checkbox>
                  <div class="info">
                    <div
                      class="title"
                      :style="
                        item.SelPropsText.length <= 0
                          ? '-webkit-line-clamp: 2'
                          : ''
                      "
                      @click="viewGoods(item.GoodsID)"
                    >
                      {{ item.PdtName }}
                    </div>
                    <div class="props">{{ item.SelPropsText }}</div>
                    <div class="price">
                      <span
                        ><label class="currency">{{ SYSLANG.currency }}</label
                        >{{ formatMoney(item.Price) }}</span
                      >
                      <van-stepper
                        v-if="!showDelete && item.Status == 1"
                        v-model="item.BuyCount"
                        min="1"
                        :max="item.stock"
                        integer
                        theme="round"
                        button-size="6vw"
                        @change="calculateTotalPrice(index)"
                        @blur="calculateTotalPrice(index)"
                      />
                    </div>
                  </div>
                </div>
              </template>
              <template #right>
                <van-button
                  square
                  :text="PAGELANG.delete"
                  type="danger"
                  class="delete-button"
                  @click="onDelete(item.ID)"
                />
              </template>
            </van-swipe-cell>
          </div>
        </van-list>
        <!--</van-pull-refresh>-->
      </template>
      <van-empty
        v-else
        :image="require('@/assets/images/notdata_cart.png')"
        image-size="180"
        :description="PAGELANG.购物车空空如也"
      >
        <van-button
          round
          type="primary"
          icon="aog1 iconfont icon-cart-02"
          to="./product"
          block
          :replace="true"
          class="btn-buynow"
          color="linear-gradient(to right, #e4c994, #cdaa66)"
          >{{ PAGELANG.马上选购 }}</van-button
        >
      </van-empty>
    </div>

    <template v-if="cart.length > 0">
      <van-submit-bar
        v-if="!showDelete"
        :decimal-length="0"
        :placeholder="true"
        :price="totalPrice"
        :currency="SYSLANG.currency"
        :button-text="PAGELANG.submit"
        :label="SYSLANG.amountto + ': '"
        button-color="linear-gradient(to right, #e4c994, #cdaa66)"
        @submit="onSubmit"
      >
        <van-checkbox
          v-model="selectAll"
          @click="checkAll"
          checked-color="var(--van-card-price-color)"
          >{{ PAGELANG.selectall }}</van-checkbox
        >
      </van-submit-bar>
      <van-submit-bar
        v-else
        :placeholder="true"
        :button-text="PAGELANG.delete"
        :price="totalPrice"
        button-color="linear-gradient(to right, #f47d7d, #ce1c1c)"
        @submit="onDelete('all')"
        class="delete-bar"
      >
        <van-checkbox
          v-model="selectAll"
          @click="checkAll"
          checked-color="var(--van-card-price-color)"
          >{{ PAGELANG.selectall }}</van-checkbox
        >
      </van-submit-bar>
    </template>
  </div>

  <div class="pc-cart" :style="'--aog-currency:\'' + SYSLANG.currency + '\';'">
    <template v-if="cart.length > 0">
      <ul
        v-infinite-scroll="getCart"
        :infinite-scroll-disabled="finished"
        class="my-cart"
      >
        <li class="cart-item header">
          <div class="pdt-name">{{ PAGELANG.商品信息 }}</div>
          <div class="count">{{ PAGELANG.buycount }}</div>
          <div class="price">{{ PAGELANG.价格 }}</div>
          <div class="control">{{ PAGELANG.操作 }}</div>
        </li>
        <li v-for="(item, index) in cart" :key="index" class="cart-item">
          <van-checkbox
            v-model="item.selected"
            @change="onChange"
            :disabled="item.Status == 0"
            shape="shape"
            checked-color="#000"
            class="thumb"
          >
            <van-image
              width="100"
              height="100"
              radius="0"
              :src="UPLOADBASE + item.PdtThumb"
              fit="cover"
            />
          </van-checkbox>
          <div class="pdt-name">
            <div class="title" @click="viewGoods(item.GoodsID)">
              {{ item.PdtName }}
            </div>
            <div class="props">{{ item.SelPropsText }}</div>
            <div class="props">{{ GOODSLANG.stock }}: {{ item.Stock }}</div>
          </div>
          <div class="count">
            <el-input-number
              v-if="!showDelete && item.Status == 1"
              v-model="item.BuyCount"
              min="1"
              :max="item.stock"
              @change="calculateTotalPrice(index)"
            />
          </div>
          <div class="price">
            {{ formatMoney(item.Price) }}
          </div>
          <div class="control">
            <span class="delete" @click="onDelete(item.ID)">{{
              PAGELANG.delete
            }}</span>
          </div>
        </li>
      </ul>
      <div class="cart-menu">
        <div class="control">
          <van-checkbox
            v-model="selectAll"
            @click="checkAll"
            checked-color="#000"
            shape="shape"
            >{{ PAGELANG.selectall }}</van-checkbox
          >
          <div class="delete" @click="onDelete('all')">
            {{ PAGELANG.delete }}
          </div>
        </div>
        <div class="submit">
          {{ SYSLANG.amountto + ": "
          }}<label class="price">{{ formatMoney(totalPrice / 100) }}</label>
          <el-button
            size="large"
            color="#cdaa66"
            style="margin-left: 20px"
            @click="onSubmit"
            >{{ PAGELANG.submit }}</el-button
          >
        </div>
      </div>
    </template>
    <van-empty
      v-else
      :image="require('@/assets/images/notdata_cart.png')"
      image-size="240"
      :description="PAGELANG.购物车空空如也"
    >
      <van-button
        round
        type="primary"
        icon="aog1 iconfont icon-cart-02"
        to="./product"
        block
        :replace="true"
        class="btn-buynow"
        color="linear-gradient(to right, #e4c994, #cdaa66)"
        >{{ PAGELANG.马上选购 }}</van-button
      >
    </van-empty>
  </div>
</template>

<script>
import {
  showConfirmDialog,
  showToast,
  showLoadingToast,
  closeToast,
} from "vant";
import { getCookie } from "../util";
export default {
  data() {
    return {
      cart: [],
      refreshing: false,
      finished: false,
      loading: false,
      curpage: 0,
      pagecount: 0,
      selectAll: false,
      showDelete: false,
      totalPrice: 0,
      count: 0,
    };
  },
  beforeCreate() {
    if (!getCookie("agentloginid")) {
      this.$router.replace({
        path: "/login",
      });
      return;
    }
  },
  created() {
    if (!getCookie("agentloginid")) {
      return;
    }

    this.PAGELANG = this.LANG.pages.cart;
    this.GOODSLANG = this.LANG.pages.goods;
    this.SYSLANG = this.LANG.system;
    this.UPLOADBASE = this.uploadbase;

    this.getCart();
  },
  methods: {
    formatMoney(money) {
      if (!money) return "0.00";
      let integer = Math.floor(money).toString();
      let thousands = integer
        .split("")
        .reverse()
        .join("")
        .replace(/(\d{3})/g, "$1.")
        .replace(/,$/, "");

      if (thousands.substr(-1, 1) == ".") {
        thousands = thousands.substring(0, thousands.length - 1);
      }

      return thousands.split("").reverse().join("");
    },
    onRefresh() {
      this.cart = [];
      this.loading = false;
      this.finished = false;
      this.curpage = 0;

      this.getCart();
    },

    getCart() {
      /*if (this.refreshing) {
        this.refreshing = false;
      }*/
      this.count++;
      console.log("count", this.loading, this.count);

      showLoadingToast({
        message: this.SYSLANG.loading,
        duration: 0,
        forbidClick: true,
      });

      /*this.loading = true;
      this.finished = true;*/
      this.curpage++;
      this.axios
        .post(this.actions.cart, {
          curpage: this.curpage,
        })
        .then(({ data }) => {
          let { code, msg, cart } = data;
          if (code == "SUCCESS") {
            closeToast();
            let { curpage, pagecount, list } = cart;

            // 读取购物车数据
            for (let i = 0; i < list.length; i++) {
              if (!list[i].selected) {
                list[i].selected = false;
              }
            }
            //console.log("cart", list);

            this.cart = this.cart.concat(list);
            this.curpage = curpage * 1;
            this.pagecount = pagecount * 1;
          } else {
            showToast({
              message:
                code == "NOTLOGIN" ? this.SYSLANG[msg] : this.PAGELANG[msg],
              forbidClick: true,
              onClose: () => {
                if (code == "NOTLOGIN") {
                  this.$router.replace({
                    path: "/login",
                  });
                }
              },
            });
          }

          this.loading = false;
          this.finished = false;

          if (this.curpage >= this.pagecount) {
            this.finished = true;
          }
        })
        .catch((error) => {
          console.log("views.Cart.getCart.error", error);
          closeToast();
          this.curpage--;
          this.getCart();
        });
    },

    viewGoods(goodsid) {
      this.$router.push({
        path: "/product/detail",
        query: {
          GoodsID: goodsid * 1,
        },
      });
    },

    checkAll() {
      for (let i = 0; i < this.cart.length; i++) {
        this.cart[i].selected = this.selectAll;
      }
      this.calculateTotalPrice();
    },

    onChange(val) {
      console.log(val);
      let checkAll = true;
      for (let i = 0; i < this.cart.length; i++) {
        if (!this.cart[i].selected) {
          checkAll = false;
          break;
        }
      }
      this.selectAll = checkAll;
      //console.log(checkAll);
      this.calculateTotalPrice();
    },

    calculateTotalPrice(index = -1) {
      if (this.showDelete) {
        return;
      }

      let totalPrice = 0;
      for (let i = 0; i < this.cart.length; i++) {
        if (this.cart[i].selected) {
          totalPrice +=
            parseInt(this.cart[i].Price * 100) *
            parseInt(this.cart[i].BuyCount);
        }
        this.totalPrice = totalPrice;
      }

      if (index >= 0) {
        // 更新购物车
        this.axios
          .post(this.actions.cart_update, {
            cartid: this.cart[index].ID,
            buycount: this.cart[index].BuyCount,
          })
          .then(({ data }) => {
            let { code, msg } = data;
            if (code != "SUCCESS") {
              console.log("更新购物车失败", msg);
            }
          })
          .catch((error) => {
            console.log("views.Cart.calculateTotalPrice.error", error);
          });
      }
    },

    onDelete(goodsid) {
      //console.log("onDelete", goodsid, typeof (goodsid * 1), goodsid % 1);
      let ids = [];
      if (goodsid == "all") {
        for (let i = 0; i < this.cart.length; i++) {
          if (this.cart[i].selected) {
            ids.push(this.cart[i].ID);
          }
        }

        if (ids.length <= 0) {
          showToast(this.PAGELANG.notselected);
          return;
        }
      } else {
        if (typeof (goodsid * 1) !== "number" && goodsid % 1 !== 0) {
          showToast(this.PAGELANG.notgoodsid);
          return;
        }
        ids.push(goodsid);
      }

      showConfirmDialog({
        title: this.SYSLANG.dialogtitle,
        message: this.PAGELANG.deleteselected,
        confirmButtonText: this.SYSLANG.queding,
        cancelButtonText: this.SYSLANG.quxiao,
      })
        .then(() => {
          // 确认删除
          showLoadingToast({
            message: this.SYSLANG.正在删除,
            duration: 0,
            forbidClick: true,
          });
          this.axios
            .post(this.actions.cart_delele, {
              ids: JSON.stringify(ids),
            })
            .then((response) => {
              closeToast();
              console.log(response);
              let { code, msg } = response.data;
              if (code == "SUCCESS") {
                showToast({
                  message: this.PAGELANG[msg],
                  onClose: () => {
                    this.onRefresh();
                  },
                });
              } else {
                showToast(
                  code == "NOTLOGIN" ? this.SYSLANG[msg] : this.PAGELANG[msg]
                );
              }
            })
            .catch((error) => {
              console.log("views.Cart.onDelete.error", error);
            });
        })
        .catch(() => {});
    },

    onSubmit(val) {
      console.log(val);
      let ids = [];
      for (let i = 0; i < this.cart.length; i++) {
        if (this.cart[i].selected) {
          ids.push(this.cart[i].ID);
        }
      }

      if (ids.length <= 0) {
        showToast(this.PAGELANG.notselected);
        return;
      }

      let postdata = [];
      let spucounts = [];
      let totalbuycount = 0;
      for (let i = 0; i < this.cart.length; i++) {
        if (this.cart[i].selected && this.cart[i].Status == 1) {
          let obj = new Object();
          obj.cartid = this.cart[i].ID * 1;
          obj.goodsid = this.cart[i].GoodsID * 1;

          let pdtsku = JSON.parse(this.cart[i].PdtSKU);
          if (pdtsku.length > 0) {
            pdtsku = pdtsku[0];
          } else {
            pdtsku = -1;
          }
          obj.pdtsku = pdtsku;

          obj.buycount = this.cart[i].BuyCount * 1;
          totalbuycount += obj.buycount;
          if (spucounts[obj.goodsid]) spucounts[obj.goodsid] += obj.buycount;
          else spucounts[obj.goodsid] = obj.buycount;

          obj.pdtname = this.cart[i].PdtName;
          obj.pdtthumb = this.cart[i].PdtThumb;
          obj.selpropstext = this.cart[i].SelPropsText;

          postdata.push(obj);
        }
      }
      console.log("cart postdata", postdata);
      console.log("spucounts", spucounts);

      let spurulepass = true;

      spucounts.forEach((itm) => {
        if (itm && (itm < 10 || itm % 10 > 0)) spurulepass = false;
      });

      if (!spurulepass || totalbuycount < 10 || totalbuycount % 10 > 0) {
        showToast(this.PAGELANG.下单单款商品数量必须是10的倍数);
        return;
      }

      this.$router.push({
        name: "placeorder",
        state: {
          orderdata: JSON.stringify(postdata),
          isfromcart: true,
        },
      });
    },
  },
};
</script>

<style scoped>
@media (max-width: 768px) {
  .pc-cart {
    display: none;
  }
  .header-menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100vw;
    height: 44px;
    box-sizing: border-box;
    padding: var(--van-card-padding);
    background-color: #fff;
    border-bottom: 1px solid #ddd;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
  }
  .header-menu-placeholder {
    height: 44px;
  }
  .my-cart {
    /*padding: var(--van-card-padding);*/
    height: calc(100vh - var(--van-tabbar-height));
    overflow-y: auto;
    padding-top: 0;
    --van-stepper-button-round-theme-color: var(--van-card-price-color);
    --van-checkbox-checked-icon-color: var(--van-card-price-color);
  }
  >>> .van-submit-bar__placeholder {
    height: 0 !important;
  }
  .cart-item {
    width: 100%;
    height: 131px;
    box-sizing: border-box;
    padding: 0 6px;
    padding-right: 0;
    border-bottom: 1px solid #ddd;
  }

  .cart-itme .c {
    width: 100%;
    height: 100%;
    align-items: center;
    display: flex;
  }

  >>> .van-checkbox--disabled .van-image .van-image__img {
    position: relative;
    z-index: 1;
  }
  >>> .van-checkbox--disabled .van-image::after {
    content: var(--goods-lose-efficacy);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: #fff;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }

  .cart-item >>> .van-checkbox__label {
    line-height: 0;
  }
  .cart-item .info {
    display: inline-flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    width: 0;
    height: 100%;
    padding: var(--van-checkbox-label-margin);
    box-sizing: border-box;
  }
  .cart-item .info .price {
    display: inline-flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  .cart-item .info .price > span {
    font-size: 6vw;
    color: var(--van-submit-bar-price-color);
  }
  .cart-item .info .price > span .currency {
    font-size: 16px;
  }

  .cart-item .info .props {
    font-size: 14px;
    color: #555;
  }

  .cart-item .info .title {
    font-size: 16px;
    color: #000;
    width: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    line-height: 24px;
    -webkit-line-clamp: 1;
    text-overflow: ellipsis;
  }
  .delete-button {
    height: 100%;
  }

  .delete-bar >>> .van-submit-bar__text {
    font-size: 0;
    --van-submit-bar-price-font-size: 0;
    --van-submit-bar-price-integer-font-size: 0;
  }

  .btn-buynow >>> .van-icon-aog {
    color: #fff;
  }

  >>> .van-submit-bar__price {
    white-space: nowrap;
  }
  >>> .van-submit-bar__price-integer {
    font-size: 0;
  }
  >>> .van-submit-bar__price-integer::before {
    content: var(--aog-totalprice);
    font-size: 6vw;
    font-family: var(--van-submit-bar-price-font);
  }
}
@media (min-width: 769px) {
  .m-cart {
    display: none;
  }

  .pc-cart {
    padding: calc(100vw / 1920 * 60) calc(100vw / 1920 * 360)
      calc(100vw / 1920 * 70) calc(100vw / 1920 * 360);
    background-color: #f7f7f7;
  }
}
</style>
<style src="../assets/css/cartpc.css"></style>
